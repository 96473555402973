<template>
  <v-sheet
    rounded="xl"
    class="text-start pa-6 py-8"
    maxWidth="786px"
    :width="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown ? '586px' : ''"
  >
    <stepper :completeStep="1" />
    <v-row class="px-3">
      <v-col cols="9" class="pb-0 text-medium"> Token </v-col>
      <v-col cols="3" class="pb-0 text-medium"> Decimal </v-col>
      <v-col cols="9" class="pt-2 pb-0">
        <AutocompleteToken @updated="onChangeToken" @focused="isFocusSearch = true" :defaultTokens="selectedToken" />
      </v-col>
      <v-col cols="3" class="pt-2 pb-0">
        <v-text-field
          :disabled="true"
          :value="(token && token.decimals) || 18"
          outlined
          dense
          rounded
          style="color: white !important"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between py-0">
        <div class="text-body-1">Address and amount</div>
        <v-btn
          color="primary lighten-1"
          class="text-body-1"
          small
          text
          v-if="isUploadFile"
          @click="isUploadFile = !isUploadFile"
          >Add manually</v-btn
        >
        <v-btn color="primary lighten-1" class="text-body-1" small text v-else @click="isUploadFile = !isUploadFile"
          >Add by file</v-btn
        >
      </v-col>
      <v-col cols="12" v-show="isUploadFile" class="pb-1">
        <DropDragableUpload @uploaded="uploaded($event)" />
      </v-col>
      <v-col cols="12" v-show="!isUploadFile" class="pb-1">
        <v-sheet rounded="xl" class="secondary lighten-3 pa-4">
          <codemirror :options="cmOption" ref="codeMirror" placeholder="" :code="plainData" />
        </v-sheet>
      </v-col>

      <v-col cols="12" v-if="duplicateCount || errorCount" class="pb-1">
        <v-sheet
          rounded="xl"
          class="secondary lighten-3 pa-6 frame-error"
          :style="`border-color: var(--v-error-lighten1) !important`"
        >
          <div class="mb-6 text-medium font-weight-medium">The below can not be processed</div>
          <div>
            <div class="d-flex justify-space-between" v-if="errorCount">
              <div class="text-body-1 mb-3 grey--text">{{ errorCount }} below is valid addresses or wrong amount</div>
              <v-btn
                class="primary--text pr-0 text-body-1"
                small
                text
                @click="deleteLines()"
                :loading="deleteLoading"
                :disabled="combineLoading"
                >Delete them</v-btn
              >
            </div>
            <div class="error-scrollable">
              <div v-for="(item, index) in Object.keys(errorList)" :key="index">
                Line {{ item }}: {{ errorList[item] }}
              </div>
            </div>
          </div>
          <v-divider v-if="errorCount && duplicateCount" class="my-6" />
          <div>
            <div class="d-flex justify-space-between" v-if="duplicateCount">
              <div class="text-body-1 mb-3 grey--text">{{ duplicateCount }} below address is dupplicated</div>
              <v-btn
                class="primary--text pr-0 text-body-1"
                small
                text
                @click="handleCombine()"
                :disabled="deleteLoading"
                >Combine balance</v-btn
              >
            </div>
            <div class="error-scrollable">
              <div v-for="(item, index) in Object.keys(duplicateList)" :key="index">
                Line {{ item }}: {{ duplicateList[item] }}
              </div>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-else-if="isEmptyError" class="pb-1">
        <v-sheet
          rounded="xl"
          class="secondary lighten-3 pa-6 frame-error"
          :style="`border-color: var(--v-error-lighten1) !important`"
        >
          <div class="line-error">Please upload or insert address with amount</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-else-if="!token" class="pb-1">
        <v-sheet
          rounded="xl"
          class="secondary lighten-3 pa-6 frame-error"
          :style="`border-color: var(--v-error-lighten1) !important`"
        >
          <div class="line-error">Please select token</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex py-0" :class="isUploadFile ? 'justify-space-between' : 'justify-end'">
        <MenuDownloadSample v-if="isUploadFile" />
        <v-btn
          rounded
          class="primary mt-3"
          min-width="150px"
          @click="next()"
          :loading="nextLoading"
          :disabled="isFocusSearch || combineLoading || deleteLoading || !isCorrectNetWork"
          >Next</v-btn
        >
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/display/placeholder.js'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    codemirror,
    DropDragableUpload: () => import('@/components/base/DropDragableUpload.vue'),
    AutocompleteToken: () => import('@/components/base/AutocompleteToken.vue'),
    Stepper: () => import('@/components/base/Stepper.vue'),
    MenuDownloadSample: () => import('@/components/base/MenuDownloadSample.vue'),
  },
  data() {
    return {
      isFocusSearch: false,
      plainData: '',
      isUploadFile: false,
      token: {},
      cmOption: {
        focus: true,
        placeholder: 'Insert address and amount, separate with comma',
        tabSize: 1,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        deleting: false,
      },
    }
  },
  computed: {
    ...mapState('auth', ['account', 'multiSendHandler']),
    ...mapState('bulkSendPrepare', [
      'errorList',
      'duplicateList',
      'editorData',
      'isEmptyError',
      'isSendSameValue',
      'editor',
      'nextLoading',
      'combineLoading',
      'deleteLoading',
      'codeMirrorText',
      'selectedToken',
    ]),
    ...mapGetters('bulkSendPrepare', ['errorCount', 'duplicateCount']),
    ...mapGetters('auth', ['isCorrectNetWork']),
  },
  mounted() {
    this.fetchData({ editor: this.$refs.codeMirror.codemirror.doc, multiSendHandler: this.multiSendHandler })
    this.plainData = this.codeMirrorText
    this.$refs.codeMirror.codemirror.on(
      'change',
      function () {
        if (this.isEmptyError) {
          this.changeEmptyError(false)
        }
      }.bind(this)
    )
  },
  methods: {
    ...mapActions('bulkSendPrepare', [
      'nextStep',
      'deleteLines',
      'deleteLine',
      'handleCombine',
      'fetchData',
      'validateData',
      'changeEmptyError',
    ]),
    async next() {
      const res = await this.nextStep({ selectedToken: this.token })
      if (res) this.$router.push('confirm')
    },
    onChangeToken(token) {
      this.isFocusSearch = false
      this.token = token
    },
    uploaded(res) {
      this.isUploadFile = false
      this.editor.setValue(res)
      this.$refs.codeMirror.refresh()
      this.validateData()
    },
  },
  async created() {},
}
</script>

<style>
.v-input__slot {
  background: var(--v-secondary-lighten3) !important;
}
input {
  color: white !important;
}
.CodeMirror-gutters {
  width: 50px !important;
  border-right: 0px;
  background: transparent !important;
}
.CodeMirror {
  background: transparent !important;
  height: 220px;
  color: white;
  font-size: 16px !important;
  line-height: 270% !important;
}
.line-error {
  color: var(--v-error-lighten1) !important;
  font-size: 16px;
}
.CodeMirror-selected {
  background: #3297fd !important;
}
.frame-error {
  font-size: 16px;
  border: 1px solid var(--v-error-lighten1) !important;
}
.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid white;
}
.CodeMirror pre.CodeMirror-placeholder {
  color: #999;
}

.v-select-list {
  margin-top: 10px !important;
  background: var(--v-secondary-lighten3) !important;
  cursor: pointer !important;
  border-radius: 16px !important;
  min-height: 150px !important;
  border: 1px solid gray !important;
}
.v-list-item__content {
  text-align: left;
}
.v-stepper__step--complete span i {
  color: green !important;
}
.text-medium {
  font-size: 20px !important;
  font-weight: 500 !important;
}
.error-scrollable {
  overflow-y: scroll;
  max-height: 120px;
}
.v-menu__content {
  border: none !important;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
</style>
        